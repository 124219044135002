import { useDispatch } from 'react-redux';
import { REFRESH_TOKEN_URL } from '@constants';
import axios, {
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { useAuth } from '@context';
import * as Sentry from '@sentry/react';
import { useSetAtom } from 'jotai';
import {
  getIsTokenExpired,
  getLocalAccessToken,
  getLocalRefreshToken,
  getLocalTokenExpiry,
} from '@utils';
import { RefreshTokenResponse } from './types';
import {
  hasResponseErrorAtom,
  responseErrorMessagesAtom,
  confirmationMessageAtom,
} from '../store/jotai';
import {
  addRequestToQueue,
  removeRequestFromQueue,
  setRequestInProgress,
  selectRequestQueue,
  selectIsRequestInProgress,
  getState,
} from '../store/features/auth';

// Public requests

export const http = axios.create({
  baseURL: '/api',
});

export const useRefreshAuthTokens = () => {
  const { user, setCredentials, logOut } = useAuth();

  return {
    refreshAuthTokens: async () => {
      try {
        const response: RefreshTokenResponse = await http.post(
          REFRESH_TOKEN_URL,
          {
            refreshToken: getLocalRefreshToken(),
            currentToken: getLocalAccessToken(),
            email: user?.email,
          },
          {
            baseURL: '/api',
            headers: {
              'Content-Type': 'application/json',
              withCredentials: true,
            },
          }
        );
        const { accessToken, refreshToken, tokenExpiry } = response.data;
        setCredentials({ accessToken, refreshToken, tokenExpiry }, true);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        if (
          error?.response?.status === 400 ||
          error?.response?.status === 401
        ) {
          logOut();
        }
      }
    },
  };
};

// Authorised requests

type ContentType = 'application/json' | 'multipart/form-data';

export const useAuthHttp = (_contentType: ContentType = 'application/json') => {
  const { setCredentials, logOut } = useAuth();
  const { refreshAuthTokens } = useRefreshAuthTokens();
  const setHasResponseError = useSetAtom(hasResponseErrorAtom);
  const setResponseErrorMessages = useSetAtom(responseErrorMessagesAtom);
  const setConfirmationMessage = useSetAtom(confirmationMessageAtom);
  const dispatch = useDispatch();

  const authHttp = axios.create({
    baseURL: '/api',
    withCredentials: true,
  });

  const processQueue = async () => {
    const state = getState();
    const isRequestInProgress = selectIsRequestInProgress(state);
    const requestQueue = selectRequestQueue(state);

    if (isRequestInProgress || !requestQueue?.length) return;

    dispatch(setRequestInProgress(true));
    const nextRequest = requestQueue[0]; // Get the first request without removing it yet (don't use pop)

    try {
      await nextRequest(); // Execute the request
    } catch (error) {
      console.error('Request failed: ', error);
    } finally {
      dispatch(removeRequestFromQueue());
      dispatch(setRequestInProgress(false));
      processQueue(); // Process the next request if available
    }
  };

  const enqueueRequest = <R = AxiosResponse>(request: () => Promise<R>) => {
    dispatch(addRequestToQueue(request as () => Promise<R>));
    processQueue(); // Start processing immediately if not already in progress
  };

  const executeRequestWithQueue = <T = any, R = AxiosResponse<T>>(
    config: InternalAxiosRequestConfig
  ): Promise<R> => {
    return new Promise((resolve, reject) => {
      enqueueRequest<R>(async () => {
        try {
          const response = (await authHttp(config)) as R; // Execute the request
          resolve(response);
          return response;
        } catch (error) {
          reject(error);
          throw error;
        }
      });
    });
  };

  // REQUEST
  authHttp.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      let token = getLocalAccessToken();
      if (token) {
        const tokenHasExpired = getIsTokenExpired(
          new Date(),
          new Date(getLocalTokenExpiry())
        );

        if (tokenHasExpired) {
          await refreshAuthTokens();
          // update token for the request
          token = getLocalAccessToken();
        }
      }
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    // REQUEST ERROR
    async (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      if (error?.response?.status === 401) {
        logOut();
      }

      return Promise.reject(error);
    }
  );

  // RESPONSE
  authHttp.interceptors.response.use(
    (response) => {
      if (response.data.registrationResult) {
        const { accessToken, refreshToken, tokenExpiry } = response.data.auth;
        setCredentials({ accessToken, refreshToken, tokenExpiry }, true);
      }
      return response;
    },
    // RESPONSE ERROR
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      if (error?.response?.status === 401) {
        logOut();
      }

      // Check if the status is 400 or 403 and set the jotai state to pop an error toast message
      if (error?.response?.status === 400 || error?.response?.status === 403) {
        setHasResponseError(true);
      }

      if (
        error?.response?.status === 400 &&
        error?.response?.data?.errors[0]?.errorMessage
      ) {
        const errorMessages = error.response.data.errors.map(
          (e: { errorMessage: string }) => e.errorMessage
        );
        setResponseErrorMessages(errorMessages);
      } else {
        setResponseErrorMessages([]);
      }
      setConfirmationMessage('');

      Sentry.captureException(error);

      return Promise.reject(error);

      // const originalRequest = error.config;

      // // If the error is a 401 and we have a refresh token, refresh the JWT token
      // if (
      //   error.response.status === 401 &&
      //   localStorage.getItem('refreshToken')
      // ) {
      //   refreshAuthTokens()
      //     .then((response) => {
      //       const token = getLocalAccessToken();
      //       // Re-run the original request that was intercepted
      //       originalRequest.headers.Authorization = `Bearer ${token}`;
      //       authHttp(originalRequest)
      //         .then((res) => {
      //           return res.data;
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });
      //     })
      //     .catch((err) => {
      //       // If there is an error refreshing the token, log out the user
      //       console.log(err);
      //     });
      // }

      // // Return the original error if we can't handle it
      // return Promise.reject(error);
    }
  );

  const defaultAxiosInstance = axios.create();

  defaultAxiosInstance.get = <T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> =>
    executeRequestWithQueue({
      ...config,
      method: 'get',
      url,
      headers:
        (config?.headers as AxiosHeaders) ||
        defaultAxiosInstance.defaults.headers,
    });

  defaultAxiosInstance.post = <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> =>
    executeRequestWithQueue({
      ...config,
      method: 'post',
      url,
      data,
      headers:
        (config?.headers as AxiosHeaders) ||
        defaultAxiosInstance.defaults.headers,
    });

  return defaultAxiosInstance;
};
