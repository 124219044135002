/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import ReactDOM from 'react-dom';
import { MAPBOX_APIKEY, WAREHOUSE_IMAGES_URL } from '@constants';
import { useNavigate } from 'react-router-dom';

function WarehousePopup({
  warehouse,
  onViewDetails,
}: {
  warehouse: any;
  onViewDetails: () => void;
}) {
  const warehouseImagePlaceholder = '/images/warehouse_details_placeholder.png';

  const mapWarehouseImages = warehouse.images.map((image: any) => ({
    imgPath: `${WAREHOUSE_IMAGES_URL}/${image.imageLocation}/small/${image.remoteName}`,
  }));

  const images =
    typeof warehouse.images === 'string'
      ? JSON.parse(mapWarehouseImages)
      : mapWarehouseImages;

  const fleet =
    typeof warehouse.fleet === 'string'
      ? JSON.parse(warehouse.fleet)
      : warehouse.fleet;

  // Calculate fleet details
  let vans = 0;
  let lightDutyVehicle = 0;
  let heavyDutyVehicle = 0;

  if (fleet && Object.keys(fleet).length !== 0) {
    Object.keys(fleet).forEach((key) => {
      if (
        key === 'MultiAxleArticMoreThan26t' ||
        key === 'TwoAxleRigidLessThan26t'
      ) {
        heavyDutyVehicle += fleet[key];
      } else if (
        key === 'LgvLessThan3AndHalft' ||
        key === 'VanLessThan1AndHalft'
      ) {
        vans += fleet[key];
      } else if (
        key === 'TwoAxleRigid18t' ||
        key === 'TwoAxleRigidLessThan7AndHalf5t' ||
        key === 'SpecialOrOther'
      ) {
        lightDutyVehicle += fleet[key];
      }
    });
  }

  return (
    <div className='flex flex-col gap-4 w-full'>
      <img
        src={
          images && images.length > 0
            ? `${images[0].imgPath}`
            : warehouseImagePlaceholder
        }
        alt='Warehouse'
        className='rounded-lg w-full h-40 object-cover'
      />
      <h2 className='text-lg font-semibold'>
        Warehouse in {warehouse.city || warehouse.address}
      </h2>
      <div className='text-sm text-gray-600'>
        <div className='flex justify-between'>
          <span>Size:</span>
          <span>
            {warehouse.maxStorageCapacity}{' '}
            {warehouse.storageCapacityUnitOfMeasure
              ? warehouse.storageCapacityUnitOfMeasure.toLowerCase()
              : ''}
          </span>
        </div>
        <div className='flex justify-between'>
          <span>Price:</span>
          {warehouse.palletStoragePricePerWeek ? (
            <span>
              {warehouse.currency.toUpperCase()}{' '}
              {warehouse.palletStoragePricePerWeek}{' '}
              <span className='text-xs'>per pallet per week</span>
            </span>
          ) : (
            <span>PoA</span>
          )}
        </div>
      </div>
      {fleet && Object.keys(fleet).length > 0 && (
        <div className='text-sm text-gray-600'>
          <div className='flex justify-between'>
            <span>Own fleet:</span>
            <span>
              {vans} Vans, {lightDutyVehicle} lightDutyVehicle,{' '}
              {heavyDutyVehicle} heavyDutyVehicle
            </span>
          </div>
        </div>
      )}
      <button
        type='button'
        className='flex flex-1 rounded-md px-3 py-2 text-md border border-black rounded-lg shadow-sm items-center justify-center'
        onClick={onViewDetails}
      >
        View Details
      </button>
    </div>
  );
}

export function Mapbox({
  warehouseData,
  mapPosition,
  interactive = true,
  zoomLevel = 5,
}: {
  warehouseData: any | null;
  mapPosition: { lat: number; lon: number } | null;
  interactive?: boolean;
  zoomLevel?: number;
}) {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!mapContainerRef.current || !warehouseData) return;

    if (mapRef.current) {
      mapRef.current.remove(); // Remove the existing map instance
    }

    mapboxgl.accessToken = MAPBOX_APIKEY;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [mapPosition?.lon ?? -2.436, mapPosition?.lat ?? 54.3781],
      zoom: zoomLevel,
      interactive,
    });

    const clusterMaxZoom = 10;

    mapRef.current.on('load', () => {
      mapRef.current!.addSource('warehouses', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: warehouseData.warehouses.map((warehouse: any) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [warehouse.location.lon, warehouse.location.lat],
            },
            properties: warehouse,
          })),
        },
        cluster: true,
        clusterMaxZoom, // Max zoom to cluster points
        clusterRadius: 50, // Radius of each cluster in pixels
      });

      mapRef.current!.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'warehouses',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      mapRef.current!.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'warehouses',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });

      mapRef.current!.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'warehouses',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': 'red',
          'circle-radius': 5,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });

      mapRef.current!.on('zoom', () => {
        const currentZoom = mapRef.current!.getZoom();

        if (currentZoom >= clusterMaxZoom) {
          // Remove existing markers to avoid duplicates
          document
            .querySelectorAll('.mapboxgl-marker')
            .forEach((marker) => marker.remove());

          // Add markers for unclustered points
          warehouseData.warehouses.forEach((warehouse: any) => {
            const popupNode = document.createElement('div');

            const { warehouseId } = warehouse;

            ReactDOM.render(
              <WarehousePopup
                warehouse={warehouse}
                onViewDetails={() => {
                  if (warehouseId) {
                    navigate(`/warehouse/${warehouseId}`);
                  }
                }}
              />,
              popupNode
            );

            new mapboxgl.Marker({ color: 'red' })
              .setLngLat([warehouse.location.lon, warehouse.location.lat])
              .setPopup(
                new mapboxgl.Popup({ closeOnClick: false }).setDOMContent(
                  popupNode
                )
              )
              .addTo(mapRef.current!);
          });
        } else {
          // Remove existing markers to avoid duplicates
          document
            .querySelectorAll('.mapboxgl-marker')
            .forEach((marker) => marker.remove());
        }
      });

      mapRef.current!.on('click', 'clusters', (e) => {
        const features = mapRef.current!.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0]?.properties?.cluster_id;
        if (!clusterId) return;
        if (mapRef.current) {
          const source = mapRef.current.getSource('warehouses');
          if (source && 'getClusterExpansionZoom' in source) {
            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              const geometry = features[0].geometry as GeoJSON.Point;

              mapRef.current!.easeTo({
                center: geometry.coordinates.slice() as [number, number],
                zoom: zoom ?? 5,
              });
            });
          }
        }
      });

      mapRef.current!.on('click', 'unclustered-point', (e) => {
        const geometry = e.features?.[0].geometry as GeoJSON.Point;
        const coordinates = geometry.coordinates.slice() as [number, number];

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const popupNode = document.createElement('div');

        const warehouseId = e.features?.[0]?.properties?.warehouseId;

        ReactDOM.render(
          <WarehousePopup
            warehouse={e.features?.[0].properties}
            onViewDetails={() => {
              if (warehouseId) {
                navigate(`/warehouse/${warehouseId}`);
              }
            }}
          />,
          popupNode
        );

        new mapboxgl.Popup({ closeOnClick: false })
          .setLngLat(coordinates)
          .setDOMContent(popupNode)
          .addTo(mapRef.current!);
      });

      mapRef.current!.on('mouseenter', 'clusters', () => {
        mapRef.current!.getCanvas().style.cursor = 'pointer';
      });
      mapRef.current!.on('mouseleave', 'clusters', () => {
        mapRef.current!.getCanvas().style.cursor = '';
      });
    });
  }, [mapContainerRef.current, warehouseData, mapPosition]);

  return (
    <div
      style={{ height: '1000px' }}
      ref={mapContainerRef}
      className='map-container'
    />
  );
}
